.timer-container {
    max-width: 400px; /* Adjust the width if necessary */
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #14150f;
    text-align: center;
}

.timer-container h2 {
    margin-bottom: 20px;
}

.countdown {
    font-size: 30px; /* Increase the font size of the countdown */
    font-weight: bold;
    color: #f5f529; /* Set the text color */
    border-radius: 10px;
}
