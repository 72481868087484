.App {
  text-align: center;
  font-family: Arial, sans-serif;
}

.content {
  padding: 20px;
  margin-top: 60px; /* To ensure the content doesn't overlap with the navbar */
}

main {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
}
