.calculator-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #14150f;
}

.calculator-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.input-group {
    margin-bottom: 15px;
}

.input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 20px;
}

.input-group input {
    width: 50%;
    padding: 8px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 15px;
}

button {
    margin-top: 20px;
    width: 100%;
    padding: 10px;
    background-color: #f5f529;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

button:hover {
    transform: translateY(-3px); /* Reduce the raise effect slightly */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 0 8px rgb(241, 253, 169); /* Subtle shadow and glow */
}

.result {
    margin-top: 20px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #FFFFFF;
}