.departures-container {
    max-width: 400px; /* Set the same max-width as the countdown container */
    margin: 0 auto; /* Center the container */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Similar shadow to maintain consistency */
    background-color: #14150f; /* Same background color as the countdown */
    text-align: center; /* Center the content */
}

.departures-container h1 {
    margin-bottom: 20px;
}

.departure-board {
    width: 100%;
    max-width: 400px; /* Ensure the width matches the container */
    border-collapse: separate; /* Use separate border model to allow rounded corners */
    border-spacing: 0; /* Remove default spacing between cells */
    margin-top: 20px;
    border-radius: 10px; /* Rounded corners for the table */
    overflow: hidden; /* Ensure content doesn’t overflow the rounded corners */
}

.departure-board th,
.departure-board td {
    border: 1px solid #505050;
    padding: 10px;
    background-color: #2a2b26;
    text-align: left;
    font-size: 20px;
}

.departure-board th {
    background-color: #343434;
    color: #f5f529;
    border-bottom: 2px solid #505050;
}

/* Rounded corners for the table header */
.departure-board thead th:first-child {
    border-top-left-radius: 10px; /* Top-left corner */
}

.departure-board thead th:last-child {
    border-top-right-radius: 10px; /* Top-right corner */
}

.departure-board tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px; /* Bottom-left corner */
}

.departure-board tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px; /* Bottom-right corner */
}
