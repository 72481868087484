.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    background-color: #0d0d08;
    color: white;
    border-bottom: 1px solid #505050;
    position: relative;
}

.navbar-brand {
    font-size: 2.3rem;
    font-weight: bold;
    margin: 12px 0px;
    color: #FFFFFF;
}

.burger-icon {
    font-size: 2.3rem;
    cursor: pointer;
    color: #FFFFFF;
    user-select: none; /* Prevent text selection */
}

.side-navbar {
    position: fixed;
    top: 0;
    right: -100%; /* Start off-screen */
    width: 300px; /* Adjust the width as needed */
    height: 100vh; /* Full height of the viewport */
    background-color: #0d0d08;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align items at the top */
    align-items: flex-start; /* Align items to the left */
    padding-top: 20px; /* Add some padding at the top */
    transition: right 0.3s ease;
    z-index: 1000; /* Ensure it stays above other content */
    padding-left: 20px; /* Add padding to the left of the links */
}

.side-navbar.open {
    right: 0; /* Slide in from the right */
}

.side-navbar-links {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%; /* Ensure full width */
}

.side-navbar-links li {
    margin: 20px 0;
}

.side-navbar-links li a {
    color: white;
    text-decoration: none;
    font-size: 1.6rem; /* Adjusted text size for the side menu */
    transition: color 0.3s ease;
}

.side-navbar-links li a:hover {
    color: #a1a1a1;
}

/* Overlay to close the side navbar when clicking outside */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999; /* Just below the navbar */
}
